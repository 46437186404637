import React from 'react'

const NotFoundPage: React.FC = () => (
  <>
    <h2>Nothing Here</h2>
    <p>Check that you followed the correct address.</p>
  </>
)

export default NotFoundPage
